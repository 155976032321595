import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Deload Week`}</strong></p>
    <p>{`Snatch/OHS mobility work`}</p>
    <p>{`then,`}</p>
    <p>{`With a :30 running clock:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`15 GHD SItups`}</p>
    <p>{`15 Back Extensions`}</p>
    <p>{`300M Ski Erg`}</p>
    <p>{`30 Full Crunch`}</p>
    <p>{`30 Superman Extension`}</p>
    <p>{`Not scored.`}</p>
    <p>{`*`}{`focus on moving correctly at 60-70% intensity.`}</p>
    <p><strong parentName="p">{`*`}{`This Friday the 6pm class will be cancelled to set up for Saturday’s
 Granite Games Winter Throwdown.  If you can help us set up please let
us know.  The more the merrier!  `}</strong></p>
    <p><strong parentName="p">{`*`}{`The gym will be closed Saturday for the Winter Throwdown but if you
aren’t competing or volunteering come out and cheer on the competitors!
 The first heat starts at 9:00am.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      